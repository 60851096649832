import {
  Logger,
  generateError,
  safeJsonParse,
  isDHPError
} from '../utils/helperMethods'
import { useContext } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import { RESOURCE_URI_FALLBACK, SERVICE_ID } from '../configs/constants'

const useCdm = () => {
  const { sessionId, sendDHPRequest: plugin } = useContext(ConfigContext)

  const _handleRequest = async (payload, useCache = false) => {
    Logger.log(`Fetching DHP - payload ${JSON.stringify(payload)}`)
    const response = await plugin(payload, useCache)

    if (isDHPError(response)) {
      return generateError(response)
    }

    if (response?.body?.data) {
      const data = response.body.isBase64Encoded
        ? atob(response.body.data)
        : response.body.data
      const parsedData = safeJsonParse(data)
      if (parsedData.errorType) {
        return generateError(parsedData)
      }
      response.body.data = parsedData
    }

    Logger.log(`Fetched DHP - response ${JSON.stringify(response)}`)
    return response
  }

  const fetch = async (href, useCache = false) => {
    const options = {
      path: href,
      sessionId: sessionId,
      method: 'GET',
      calledBy: SERVICE_ID
    }
    return _handleRequest(options, useCache)
  }

  const patch = async (href, payload) => {
    const options = {
      path: href,
      sessionId: sessionId,
      method: 'PATCH',
      body: payload,
      allowUserInteraction: true,
      calledBy: SERVICE_ID
    }
    return _handleRequest(options)
  }

  const fetchServicesDiscovery = async () => {
    return fetch(RESOURCE_URI_FALLBACK.CDM.serviceDiscovery, true)
  }

  return {
    fetch,
    patch,
    fetchServicesDiscovery
  }
}

export default useCdm

import useCdm from './useCdm'
import useLedm from './useLedm'
import {
  Logger,
  getDeviceSetupHref,
  getOobeManifestHref,
  getOobeConfigHref,
  safeJsonParse
} from '../utils/helperMethods'

const usePrinter = (
  isLedm = false,
  discoveryTree = null,
  oobeManifestTree = null
) => {
  const _discoveryTree = safeJsonParse(discoveryTree)
  const _oobeManifestTree = safeJsonParse(oobeManifestTree)
  const { fetchServicesDiscovery, patch: patchCdm } = useCdm()
  const { fetch: ledmFetch, fetchDiscoveryTree, patch: patchLedm } = useLedm()

  const fetchDiscovery = async () => {
    return isLedm ? await fetchDiscoveryTree() : await fetchServicesDiscovery()
  }

  const fetchOobeManifest = async () => {
    if (!isLedm) {
      Logger.warn('CDM does not support OOBE manifest')
      return null
    }

    return ledmFetch(getOobeManifestHref(_discoveryTree), true)
  }

  const patchStatus = async (payload) => {
    if (isLedm) {
      return patchLedm(getOobeConfigHref(_oobeManifestTree), payload)
    }
    return patchCdm(getDeviceSetupHref(_discoveryTree), payload)
  }

  return {
    fetchDiscovery,
    fetchOobeManifest,
    patchStatus
  }
}

export default usePrinter

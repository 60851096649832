// DeviceHttpProxy error code map
export const DHP_ERROR_CODE = {
  invalidArgument: 'OW_DHP00002',
  dataValveError: 'OW_DHP00003',
  invalidSession: 'HS_DHP00001',
  sessionNotActive: 'HS_DHP00002',
  deviceOffline: 'HS_DHP00003',
  requestTimedOut: 'FW_DHP00001',
  cancelled: 'FW_DHP00002',
  unknownError: 'FW_DHP00003',
  requestRejected: 'HS_DHP00004', // non-DHP error type
  discoveryTreeEmpty: 'FW_DHP00004', // non-DHP error type
  oobeManifestEmpty: 'FW_DHP00005' // non-DHP error type
}
export const DEVICE_NOT_SUPPORTED = 'notSupported'
export const DEVICE_LEDM_ONLY = 'ledmOnly'
export const DEVICE_LEDM_AND_CDM = 'ledmAndCdm'
export const DEVICE_CDM_ONLY = 'cdmOnly'
export const DEFAULT_ERROR_CODE = 'OW_DHP00001'
export const DHP_NON_200_ERROR_CODE = 'OW_DHP0004'
export const DHP_REQUEST_REJECTED_ERROR = 'requestRejected'
export const DHP_DISCOVERY_TREE_EMPTY_ERROR = 'discoveryTreeEmpty'
export const DHP_OOBE_MANIFEST_TREE_EMPTY_ERROR = 'oobeManifestEmpty'
export const SERVICE_ID = 'owsc-jwv-software-completion'

export const DIA_ERROR_CODE = 'DIA0001'

export const EMPTY_FUNCTION = () => {
  // Intentional empty function to avoid sonarqube code smells
}

export const RESULT = {
  FAILURE: 'failure',
  DECLINED: 'declined'
}

export const NO_REASON_MESSAGE_DIA = 'Error calling Device IOT API (no reason)'

export const CDM_PAYLOAD = {
  data: '{"softwareCompletionState": "completed"}'
}

export const LEDM_PAYLOAD = {
  data: '<?xml version="1.0" encoding="UTF-8"?><ob:OOBEConfig xsi:schemaLocation="http://www.hp.com/schemas/imaging/con/ledm/oobe/2015/12/14 ../OOBE/OOBE.xsd" xmlns:dd="http://www.hp.com/schemas/imaging/con/dictionaries/1.0/" xmlns:ob="http://www.hp.com/schemas/imaging/con/ledm/oobe/2015/12/14" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"><ob:OWSSetupCompleted>true</ob:OWSSetupCompleted></ob:OOBEConfig>'
}

export const DEVICE_API_STATE_VALUE = {
  desired: {
    cdmData: {
      softwareCompletionState: 'completed'
    }
  }
}

export const DEVICE_API_RESOURCE_URI_VALUE = '/$cloudId/deviceSetup/status'

// Analytics
const BASE_PATH = '/SoftwareCompletion/'

const ACTIVITY = 'HardwareSetup-v01'

export const ANALYTICS = {
  ACTIVITY,
  BASE_PATH,
  SUCCESS: 'Success',
  FAILURE: 'Failure',
  SCREENS: {
    START: {
      screenName: 'Start',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    },
    COMPLETED: {
      screenName: 'Completed',
      activity: ACTIVITY,
      screenPath: BASE_PATH
    }
  }
}

export const RESOURCE_URI_FALLBACK = {
  CDM: {
    deviceSetup: '/cdm/deviceSetup/v1/status',
    serviceDiscovery: '/cdm/servicesDiscovery'
  },
  LEDM: {
    oobeConfig: '/OOBE/Config',
    oobeManifest: '/OOBE/OOBEManifest.xml',
    discoveryTree: '/DevMgmt/DiscoveryTree.xml'
  }
}

// Monitoring
export const SPAN_WORKFLOW_NAME = 'workflow.name'
export const SPAN_FLOW_TYPE = 'flow.type'
export const SPAN_FLOW_INTERFACE = 'flow.interface'
export const SPAN_FLOW_RESOURCE = 'flow.resource'
export const SPAN_FLOW_PAYLOAD = 'flow.payload'
export const SPAN_MODEL_NAME = 'model.name'
export const SPAN_PRODUCT_NUMBER = 'model.number'
export const DEVICE_HTTP_PROXY_FLOW_TYPE = 'DeviceHttpProxy'
export const DEVICE_API_CLIENT_FLOW_TYPE = 'DeviceApi'
export const CDM_FLOW_INTERFACE_TYPE = 'CDM'
export const LEDM_FLOW_INTERFACE_TYPE = 'LEDM'

// Cache Strategy
export const CACHE_STRATEGY_CACHEFIRST = 'cacheFirst'
export const MAX_AGE_IN_SECONDS = 1800

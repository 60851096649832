const { XMLParser } = require('fast-xml-parser')
import { Logger, generateError, isDHPError } from '../utils/helperMethods'
import { useContext } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import { RESOURCE_URI_FALLBACK, SERVICE_ID } from '../configs/constants'

const useLedm = () => {
  const { sessionId, sendDHPRequest: plugin } = useContext(ConfigContext)
  const parser = new XMLParser({
    allowBooleanAttributes: true,
    ignoreDeclaration: true,
    processEntities: false
  })

  const _handleRequest = async (payload, useCache = false) => {
    Logger.log(`Fetching DHP LEDM - payload ${JSON.stringify(payload)}`)
    const response = await plugin(payload, useCache)

    if (isDHPError(response)) {
      return generateError(response)
    }

    if (response?.body?.data) {
      const data = response.body.isBase64Encoded
        ? window.atob(response.body.data)
        : response.body.data
      try {
        response.body.data = parser.parse(data, true)
      } catch (e) {
        return generateError({ errorType: 'fast-xml-parser error', reason: e })
      }
    }

    Logger.log(`Fetched DHP LEDM - response ${JSON.stringify(response)}`)
    return response
  }

  const fetch = async (href, useCache = false) => {
    const options = {
      path: href,
      sessionId: sessionId,
      method: 'GET',
      calledBy: SERVICE_ID
    }
    return _handleRequest(options, useCache)
  }

  const patch = async (href, payload) => {
    const options = {
      path: href,
      sessionId: sessionId,
      method: 'PUT',
      body: payload,
      headers: {
        'Content-Type': 'text/xml'
      },
      allowUserInteraction: true,
      calledBy: SERVICE_ID
    }
    return _handleRequest(options)
  }

  const fetchDiscoveryTree = async () => {
    return fetch(RESOURCE_URI_FALLBACK.LEDM.discoveryTree, true)
  }

  return {
    fetch,
    patch,
    fetchDiscoveryTree
  }
}

export default useLedm

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".E9sjnjSfrcgbUA5eV978{height:100%}.E9sjnjSfrcgbUA5eV978 .NXqMVxgLGbfKa1k3qLGm{height:100%;display:flex;flex-direction:column;justify-content:center;align-items:center}.E9sjnjSfrcgbUA5eV978 .hRgkcqVSf7yIdlBwoD3y{display:flex;justify-content:center;align-items:center;min-height:calc(100vh - 16px)}.E9sjnjSfrcgbUA5eV978 div[role=progressbar]{width:50px;height:50px}", "",{"version":3,"sources":["webpack://./src/components/Completion/SoftwareCompletion.module.scss"],"names":[],"mappings":"AAAA,sBACC,WAAA,CAEA,4CACC,WAAA,CACA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CAGD,4CACC,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,6BAAA,CAGD,4CACC,UAAA,CACA,WAAA","sourcesContent":[".completed {\n\theight: 100%;\n\n\t.progress-bar-hpx {\n\t\theight: 100%;\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t}\n\n\t.progress-bar {\n\t\tdisplay: flex;\n\t\tjustify-content: center;\n\t\talign-items: center;\n\t\tmin-height: calc(100vh - 2 * 8px);\n\t}\n\n\tdiv[role='progressbar'] {\n\t\twidth: 50px;\n\t\theight: 50px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"completed": "E9sjnjSfrcgbUA5eV978",
	"progress-bar-hpx": "NXqMVxgLGbfKa1k3qLGm",
	"progress-bar": "hRgkcqVSf7yIdlBwoD3y"
};
export default ___CSS_LOADER_EXPORT___;

import React, { useCallback, useMemo, useState } from 'react'
import { Stack } from '@jarvis/web-stratus-client'
import useProxyController from '../hooks/useProxyController'
import { Logger } from '../utils/helperMethods'
import {
  DEVICE_CDM_ONLY,
  DEVICE_LEDM_AND_CDM,
  DEVICE_LEDM_ONLY,
  DEVICE_NOT_SUPPORTED,
  EMPTY_FUNCTION,
  RESULT
} from '../configs/constants'

export const ConfigContext = React.createContext({
  authProvider: null,
  sessionContext: null,
  country: null,
  language: null,
  localization: null,
  stack: Stack.pie,
  init: EMPTY_FUNCTION,
  sessionId: null,
  closeServiceInstance: EMPTY_FUNCTION,
  sendDHPRequest: null,
  isLedm: null,
  isCdm: null,
  fwProtocolCapability: null,
  publishCdmEvent: EMPTY_FUNCTION,
  publishRumEvent: EMPTY_FUNCTION
})
const DEFAULT_COUNTRY = 'us'
const DEFAULT_LANGUAGE = 'en'

const ConfigProvider = (props) => {
  const {
    authProvider = null,
    analytics = null,
    store = null,
    localization = {
      country: DEFAULT_COUNTRY,
      language: DEFAULT_LANGUAGE,
      enabled: false
    },
    stack = Stack.pie,
    serviceRouting,
    monitoring = null
  } = props

  const country = (localization.country || DEFAULT_COUNTRY).toUpperCase()
  const language = localization.language || DEFAULT_LANGUAGE
  const { sendDHPRequest, initializeDeviceHttpProxy } = useProxyController()
  const sessionContext = store?.state?.onboarding?.sessionContext
  const publishCdmEvent = analytics?.publishCdmEvents || EMPTY_FUNCTION
  const [sessionId, setSessionId] = useState(null)
  const fwProtocolCapability =
    sessionContext?.device?.fwProtocolCapability || DEVICE_NOT_SUPPORTED
  /* Treat hybrid printer to be LEDM */
  const isLedm =
    fwProtocolCapability === DEVICE_LEDM_ONLY ||
    fwProtocolCapability === DEVICE_LEDM_AND_CDM
  const isCdm = fwProtocolCapability === DEVICE_CDM_ONLY
  const startSpan = monitoring?.startSpan || EMPTY_FUNCTION

  const closeServiceInstance = useCallback(
    async (errorInfo = null, result = RESULT.FAILURE) => {
      const xCorrelationId = sessionContext?.xCorrelationId
      const closeServiceInstanceOptions = {
        resultData: {
          result: 'success',
          xCorrelationId: xCorrelationId
        }
      }
      if (errorInfo !== null) {
        closeServiceInstanceOptions.resultData = {
          result: result,
          errorInfo: errorInfo
        }
      }
      Logger.log('calling closeServiceInstance')
      Logger.log(closeServiceInstanceOptions)
      try {
        await serviceRouting.closeServiceInstance(closeServiceInstanceOptions)
      } catch (error) {
        Logger.error(`call to closeServiceInstance failed - ${error}`)
      }
    },
    [serviceRouting, sessionContext]
  )

  const _getAppSessionId = useCallback(async () => {
    let id = null
    try {
      const serviceLaunchOptions =
        await serviceRouting.getServiceInstanceLaunchOptions()
      Logger.log('calling getServiceInstanceLaunchOptions')
      Logger.log(serviceLaunchOptions)
      const { serviceOptions } = serviceLaunchOptions
      if (serviceOptions.appSessionId) {
        id = serviceOptions.appSessionId
      }
    } catch (e) {
      Logger.error(`call to getServiceInstanceLaunchOptions failed - ${e}`)
    }
    Logger.log(`appSessionId - ${id}`)
    return id
  }, [serviceRouting])

  const init = useCallback(async () => {
    await initializeDeviceHttpProxy()
    const appSessionId = await _getAppSessionId()
    setSessionId(appSessionId)
  }, [_getAppSessionId, initializeDeviceHttpProxy])

  const _publishRumEvent = useCallback(
    async (name, attributes = {}) => {
      const span = startSpan?.(name, attributes)
      await span?.end?.()
    },
    [startSpan]
  )

  const configState = useMemo(
    () => ({
      authProvider,
      sessionContext,
      localization,
      sessionId,
      country,
      language,
      stack,
      closeServiceInstance,
      init,
      sendDHPRequest,
      isLedm,
      isCdm,
      fwProtocolCapability,
      publishCdmEvent,
      publishRumEvent: _publishRumEvent
    }),
    [
      authProvider,
      closeServiceInstance,
      country,
      fwProtocolCapability,
      localization,
      init,
      isCdm,
      isLedm,
      language,
      sendDHPRequest,
      sessionContext,
      sessionId,
      stack,
      publishCdmEvent,
      _publishRumEvent
    ]
  )

  return (
    <ConfigContext.Provider value={configState}>
      {props.children}
    </ConfigContext.Provider>
  )
}

export default ConfigProvider

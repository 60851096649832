import React from 'react'
import ConfigProvider from '../../store/ConfigContext'
import PrinterProvider from '../../store/PrinterContext'
import App from './App'
import { BrowserRouter as Router } from 'react-router-dom'

const Home = (props) => {
  return (
    <Router>
      <ConfigProvider {...props}>
        <PrinterProvider>
          <App />
        </PrinterProvider>
      </ConfigProvider>
    </Router>
  )
}

export default Home

import { useContext, useState } from 'react'
import { ConfigContext } from '../store/ConfigContext'
import { ANALYTICS } from '../configs/constants'

const buildEventPayload = (action, activity, control, screen) => {
  const eventDetail = {
    action,
    screenName: screen.name,
    screenPath: screen.path,
    version: '1.4.0'
  }
  if (activity) {
    eventDetail.activity = activity
  }
  if (screen.mode) {
    eventDetail.screenMode = screen.mode
  }
  if (control.actionAuxParams) {
    eventDetail.actionAuxParams = control.actionAuxParams
  }

  return {
    dateTime: new Date().toISOString(),
    eventDetailType:
      'com.hp.cdm.domain.telemetry.type.eventDetail.category.simpleUi.version.1',
    eventCategory: 'simpleUi',
    version: '1.4.0',
    eventDetail
  }
}

function useAnalytics(screen, checkDone = true) {
  const { publishCdmEvent } = useContext(ConfigContext)
  const [done, setDone] = useState(false)
  const { activity, screenPath, screenName } = screen

  const _checkScreenValidity = () => {
    if (Object.values(ANALYTICS.SCREENS).indexOf(screen) === -1) {
      throw new Error('Invalid screen')
    }
  }

  const _buildEvent = (action, { mode = null, errorCode = null }) => {
    let actionAuxParams = null
    if (errorCode) {
      actionAuxParams = new URLSearchParams({ errorCode: errorCode }).toString()
    }
    return [
      buildEventPayload(
        action,
        activity,
        {
          actionAuxParams
        },
        {
          name: screenName,
          mode,
          path: screenPath
        }
      )
    ]
  }

  const fireScreenDisplayed = (props = {}) => {
    _checkScreenValidity()
    if (checkDone && done && !props.force) return
    setDone(true)
    publishCdmEvent(_buildEvent('ScreenDisplayed', props))
  }

  return {
    fireScreenDisplayed
  }
}

export default useAnalytics

const { DeviceApiClient } = require('@jarvis/web-stratus-client')

class DeviceShadowClient extends DeviceApiClient {
  setDeviceSetupStatus(cloudId, state, headers) {
    return this.jarvisWebHttpInstance.post({
      url: `/${cloudId}/deviceSetup/status`,
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      data: { state }
    })
  }
}

module.exports = { DeviceShadowClient }

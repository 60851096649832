import { useCallback, useRef } from 'react'
import { jWebReady as WhenJWebReady } from '@jarvis/jweb-core'
import { Logger } from '../utils/helperMethods'
import {
  DHP_ERROR_CODE,
  DHP_REQUEST_REJECTED_ERROR,
  CACHE_STRATEGY_CACHEFIRST,
  MAX_AGE_IN_SECONDS
} from '../configs/constants'

const useProxyController = () => {
  const deviceHttpProxy = useRef(null)

  const initializeDeviceHttpProxy = useCallback(async () => {
    let response = await WhenJWebReady
    response = typeof response !== 'function' ? response : await response()

    deviceHttpProxy.current = response?.Plugins?.DeviceHttpProxy || null
    if (!deviceHttpProxy.current) {
      Logger.error('JWeb plugin: DeviceHttpProxy not available')
    }
  }, [])

  const sendDHPRequest = useCallback(
    async (options, useCache = false) => {
      try {
        if (useCache && deviceHttpProxy.current.sendRequestWithCacheStrategy) {
          Logger.log('Calling DHP plugin sendRequestWithCacheStrategy')
          return await deviceHttpProxy.current.sendRequestWithCacheStrategy({
            request: options,
            cacheStrategy: CACHE_STRATEGY_CACHEFIRST,
            maxAgeInSeconds: MAX_AGE_IN_SECONDS
          })
        }
        Logger.log('Calling DHP plugin sendRequest')
        return await deviceHttpProxy.current.sendRequest(options)
      } catch (error) {
        const errorType = DHP_ERROR_CODE[error]
          ? error
          : DHP_REQUEST_REJECTED_ERROR
        const reason = `Call to DHP plugin has failed - plugin has not been initialized and/or is not available - ${error}`
        Logger.warn(reason)
        return { errorType, reason }
      }
    },
    [deviceHttpProxy]
  )

  return {
    deviceHttpProxy,
    initializeDeviceHttpProxy,
    sendDHPRequest
  }
}

export default useProxyController

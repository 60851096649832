import {
  DEFAULT_ERROR_CODE,
  DHP_ERROR_CODE,
  SERVICE_ID,
  RESOURCE_URI_FALLBACK,
  DHP_NON_200_ERROR_CODE,
  SPAN_FLOW_INTERFACE,
  CDM_FLOW_INTERFACE_TYPE,
  LEDM_FLOW_INTERFACE_TYPE,
  SPAN_FLOW_PAYLOAD,
  SPAN_FLOW_RESOURCE
} from '../configs/constants'

const packageJson = require('../../package.json')
const { CDM: CDM_URI, LEDM: LEDM_URI } = RESOURCE_URI_FALLBACK
const logWithPrefix = (severity, log) => {
  console[severity](
    `SoftwareCompletion - ${packageJson.version} - `,
    typeof log === 'string' ? log : JSON.stringify(log)
  )
}

export const Logger = {
  debug: (log) => logWithPrefix('debug', log),
  log: (log) => logWithPrefix('log', log),
  info: (log) => logWithPrefix('info', log),
  warn: (log) => logWithPrefix('warn', log),
  error: (log) => logWithPrefix('error', log)
}

export const range = (start, end) => {
  return Array(end - start + 1)
    .fill(0)
    .map((_, idx) => start + idx)
}

export const getDeviceSetupHref = (servicesDiscovery) => {
  try {
    return servicesDiscovery['services'].find(
      (service) =>
        service['serviceGun'] === 'com.hp.cdm.service.deviceSetup.version.1'
    ).links[0].href
  } catch (e) {
    let log = `Not able to find service - ${e}`
    if (servicesDiscovery) {
      log += ` - ${JSON.stringify(servicesDiscovery)}`
    }
    Logger.warn(log)
    return CDM_URI.deviceSetup
  }
}

const getManifestUri = (discoveryTree, type) => {
  try {
    return discoveryTree['ledm:DiscoveryTree']['ledm:SupportedIfc'].find(
      (service) => service['dd:ResourceType'] === type
    )['ledm:ManifestURI']
  } catch (e) {
    let log = `Not able to find manifest uri for ${type} type - ${e}`
    if (discoveryTree) {
      log += ` - ${JSON.stringify(discoveryTree)}`
    }
    Logger.warn(log)
    return null
  }
}

export const getOobeManifestHref = (discoveryTree) => {
  return (
    getManifestUri(discoveryTree, 'ledm:hpOOBEManifest') ||
    LEDM_URI.oobeManifest
  )
}

export const getOobeConfigHref = (manifestTree) => {
  try {
    const basePath =
      manifestTree['man:Manifest']['map:ResourceMap']['map:ResourceLink'][
        'dd:ResourceURI'
      ]
    const oobeConfigUri = manifestTree['man:Manifest']['map:ResourceMap'][
      'map:ResourceNode'
    ].find(
      (resource) =>
        resource['map:ResourceType']['ob:OOBEResourceType'] === 'Config'
    )['map:ResourceLink']['dd:ResourceURI']
    return basePath + oobeConfigUri
  } catch (e) {
    let log = `Not able to find OOBE Config href - ${e}`
    if (manifestTree) {
      log += `- ${JSON.stringify(manifestTree)}`
    }
    Logger.warn(log)
    return LEDM_URI.oobeConfig
  }
}

export const generateError = (error) => {
  const errorCode =
    DHP_ERROR_CODE[error.errorType] ||
    (error.statusCode ? DHP_NON_200_ERROR_CODE : DEFAULT_ERROR_CODE)
  return {
    errorCode: `${SERVICE_ID}.${errorCode}`,
    errorDescription: `${error.errorType || error.statusCode} - ${
      error.reason ? error.reason : 'Error calling DHP plugin (no reason)'
    }`,
    errorCodeLabel: error.statusCode
      ? `${errorCode}_${error.statusCode}`
      : errorCode
  }
}

export const isDHPError = (response) => {
  return !!(
    response?.errorType || range(200, 299).indexOf(response?.statusCode) === -1
  )
}

export const safeJsonParse = (jsonString) => {
  try {
    return JSON.parse(jsonString)
  } catch (error) {
    return {
      errorType: 'JSON.parse error',
      reason: error.message
    }
  }
}

export const getSplunkRumSpanFlow = (flowType, isLedm, payload, resource) => {
  const attributes = {
    ...(resource && { [SPAN_FLOW_RESOURCE]: resource }),
    ...(payload && { [SPAN_FLOW_PAYLOAD]: JSON.stringify(payload) })
  }

  attributes[SPAN_FLOW_INTERFACE] = isLedm
    ? LEDM_FLOW_INTERFACE_TYPE
    : CDM_FLOW_INTERFACE_TYPE

  return attributes
}

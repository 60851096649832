import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { ConfigContext } from './ConfigContext'
import {
  generateError,
  getSplunkRumSpanFlow,
  Logger
} from '../utils/helperMethods'
import {
  EMPTY_FUNCTION,
  SERVICE_ID,
  SPAN_FLOW_TYPE,
  SPAN_WORKFLOW_NAME,
  DEVICE_HTTP_PROXY_FLOW_TYPE,
  DHP_OOBE_MANIFEST_TREE_EMPTY_ERROR,
  DHP_DISCOVERY_TREE_EMPTY_ERROR,
  DHP_ERROR_CODE,
  SPAN_MODEL_NAME,
  SPAN_PRODUCT_NUMBER,
  RESOURCE_URI_FALLBACK
} from '../configs/constants'
import usePrinter from '../hooks/usePrinter'

export const PrinterContext = React.createContext({
  discoveryTree: null,
  oobeManifestTree: null,
  init: EMPTY_FUNCTION,
  productNumber: null,
  modelName: null
})

const PrinterProvider = (props) => {
  const { sessionContext } = useContext(ConfigContext)
  const [discoveryTree, setDiscoveryTree] = useState(null)
  const [oobeManifestTree, setOobeManifestTree] = useState(null)
  const [fetchedManifest, setFetchedManifest] = useState(false)
  const { isLedm, closeServiceInstance, publishRumEvent } =
    useContext(ConfigContext)
  const [productNumber, setProductNumber] = useState(null)
  const [modelName, setModelName] = useState(null)

  const { fetchDiscovery, fetchOobeManifest } = usePrinter(
    isLedm,
    discoveryTree,
    oobeManifestTree
  )

  const triggerPublishRumEvent = useCallback(
    (workflowName, resource) => {
      publishRumEvent(SERVICE_ID, {
        [SPAN_WORKFLOW_NAME]: workflowName,
        [SPAN_FLOW_TYPE]: DEVICE_HTTP_PROXY_FLOW_TYPE,
        [SPAN_MODEL_NAME]: modelName,
        [SPAN_PRODUCT_NUMBER]: productNumber,
        ...getSplunkRumSpanFlow(
          DEVICE_HTTP_PROXY_FLOW_TYPE,
          isLedm,
          null,
          resource
        )
      })
    },
    [isLedm, modelName, productNumber, publishRumEvent]
  )

  /* Async hook callback for fetching OOBE manifest tree */
  const _fetchManifest = useCallback(async () => {
    const response = await fetchOobeManifest()
    if (!response?.body?.data || response?.errorCode) {
      Logger.warn('OOBE manifest tree is empty or unable to fetch')

      triggerPublishRumEvent(
        response?.errorCodeLabel ||
          DHP_ERROR_CODE[DHP_OOBE_MANIFEST_TREE_EMPTY_ERROR],
        RESOURCE_URI_FALLBACK.LEDM.oobeManifest
      )

      const error = response?.errorCode
        ? response
        : generateError({ errorType: DHP_OOBE_MANIFEST_TREE_EMPTY_ERROR })

      closeServiceInstance(error)
      return
    }
    Logger.log(`OOBE manifest tree fetched`)
    setOobeManifestTree(JSON.stringify(response?.body?.data))
  }, [closeServiceInstance, fetchOobeManifest, triggerPublishRumEvent])

  const _fetchDeviceInfo = useCallback(() => {
    setModelName(sessionContext?.device?.modelName)
    setProductNumber(sessionContext?.device?.productNumber)
    Logger.log(`model name - ${sessionContext?.device?.modelName}`)
    Logger.log(`product number - ${sessionContext?.device?.productNumber}`)
  }, [sessionContext?.device?.modelName, sessionContext?.device?.productNumber])

  const init = useCallback(async () => {
    const response = await fetchDiscovery()
    if (!response?.body?.data || response?.errorCode) {
      Logger.warn('Discovery tree is empty or unable to fetch')

      triggerPublishRumEvent(
        response?.errorCodeLabel ||
          DHP_ERROR_CODE[DHP_DISCOVERY_TREE_EMPTY_ERROR],
        isLedm
          ? RESOURCE_URI_FALLBACK.LEDM.discoveryTree
          : RESOURCE_URI_FALLBACK.CDM.serviceDiscovery
      )

      const error = response?.errorCode
        ? response
        : generateError({ errorType: DHP_DISCOVERY_TREE_EMPTY_ERROR })

      closeServiceInstance(error)
      return
    }

    Logger.log(`Discovery tree fetched`)

    setDiscoveryTree(JSON.stringify(response?.body?.data))
  }, [closeServiceInstance, fetchDiscovery, isLedm, triggerPublishRumEvent])

  useEffect(() => {
    _fetchDeviceInfo()
    if (discoveryTree && !oobeManifestTree && isLedm && !fetchedManifest) {
      Logger.log(`Fetching OOBE manifest for LEDM`)

      setFetchedManifest(true)
      _fetchManifest()
    }
  }, [
    discoveryTree,
    isLedm,
    oobeManifestTree,
    fetchedManifest,
    _fetchManifest,
    _fetchDeviceInfo
  ])

  const printerState = useMemo(
    () => ({
      discoveryTree,
      oobeManifestTree,
      init,
      productNumber,
      modelName
    }),
    [discoveryTree, init, modelName, oobeManifestTree, productNumber]
  )

  return (
    <PrinterContext.Provider value={printerState}>
      {props.children}
    </PrinterContext.Provider>
  )
}

export default PrinterProvider

import { Stack } from '@jarvis/web-stratus-client'
import React, { useEffect } from 'react'
import Home from '../src/components/Home'
import { ShellProps } from './types/shell'
const naming = require('../src/configs/projectNames.js')
import { Logger } from '../src/utils/helperMethods.js'
import { ThemeProvider } from '@veneer/theme'
import {
  useMultiUiBrandContext,
  getThemeProviderConfig
} from '@jarvis/react-setup-and-onboarding-addons'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({
  stack = Stack.pie,
  properties = {
    ldClientId: '60a54dfefc74a80e2cec9529'
  }
}) {
  const { uiBrand } = useMultiUiBrandContext()
  const {
    v1: {
      analytics,
      store,
      localization,
      navigation,
      serviceRouting,
      monitoring
    } = {},
    v2: { authProvider } = {}
  } = window.Shell as ShellProps

  useEffect(() => {
    Logger.log('Mounted Software Completion Component')
    return () => Logger.log('Unmounted Software Completion Component')
  }, [])

  return (
    <section id={naming.packageJsonName}>
      <ThemeProvider {...getThemeProviderConfig(uiBrand)}>
        <Home
          authProvider={authProvider}
          store={store}
          stack={stack}
          analytics={analytics}
          navigation={navigation}
          localization={localization}
          ldClientId={properties.ldClientId}
          serviceRouting={serviceRouting}
          monitoring={monitoring}
        />
      </ThemeProvider>
    </section>
  )
}

import { useContext, useEffect, useRef } from 'react'
import { Logger } from '../utils/helperMethods'
import { ConfigContext } from '../store/ConfigContext'
import { DIA_ERROR_CODE } from '../configs/constants'

const useDeviceApi = () => {
  const { stack, authProvider } = useContext(ConfigContext)
  const deviceShadowClient = useRef(null)
  const { DeviceShadowClient } = require('../clients/DeviceShadowClient')

  const _reject = (error, code, prefix) => {
    let _code
    try {
      _code = error.response.data.errors.pop().code
    } catch {
      _code = code
    }
    const statusValue = error?.response?.status || 'XXX'
    return {
      status: 'error',
      message: `${prefix}_${statusValue}_${_code}`
    }
  }

  const postDeviceSetupStatus = (cloudId, state, header) => {
    Logger.log('Entered postDeviceSetupStatus')
    return deviceShadowClient.current
      .setDeviceSetupStatus(cloudId, state, header)
      .then((response) => {
        return {
          status: 'success',
          data: response.status
        }
      })
      .catch((err) => {
        return _reject(err, DIA_ERROR_CODE, 'SWC')
      })
  }

  useEffect(() => {
    deviceShadowClient.current = new DeviceShadowClient(stack, authProvider)
    return () => {
      deviceShadowClient.current = null
    }
  }, [DeviceShadowClient, authProvider, stack])

  return {
    postDeviceSetupStatus
  }
}
export default useDeviceApi
